import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import PatientForm from './PatientForm';
import PatientList from './PatientList';
import PasswordProtection from './PasswordProtection';
import './App.css';
import './Transitions.css';
import logo from './joaoribeirologo.png';

const App = () => {
  const [filter, setFilter] = useState('filterAll');
  const [patients, setPatients] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState('Todos');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const fetchPatients = async () => {
    try {
      let query = `https://backendlab.vercel.app/patients?filter=${filter}`;
      
      // Include date range if the filter is "completed"
      if (filter === 'completed' && startDate && endDate) {
        query += `&startDate=${format(startDate, 'yyyy-MM-dd')}&endDate=${format(endDate, 'yyyy-MM-dd')}`;
      }

      const response = await axios.get(query);
      setPatients(response.data);
    } catch (error) {
      console.error('Error fetching patients:', error);
    }
  };

  useEffect(() => {
    fetchPatients();
  }, [filter, startDate, endDate]);

  const openModal = (patient = null) => {
    setCurrentPatient(patient);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentPatient(null);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePatientClick = (patient) => {
    setCurrentPatient(patient);
    openModal(patient);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const filteredPatients = patients.filter(patient =>
    (patient.name && patient.name.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (selectedDoctor === 'Todos' || patient.doctorName === selectedDoctor)
  );

  const handleAuthentication = () => {
    setAuthenticated(true);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    // Clear the date range when changing filters
    if (newFilter !== 'completed') {
      setStartDate(null);
      setEndDate(null);
    }
  };

  if (!authenticated) {
    return <PasswordProtection onAuthenticated={handleAuthentication} />;
  }

  return (
    <div className="app">
      <div className="top-container">
        <img src={logo} alt="Logo" className="logo" onClick={() => handleFilterChange('filterAll')} />
        
        <div className="filters">
          <button
            onClick={() => handleFilterChange('filterAll')}
            className={filter === 'filterAll' ? 'active-filter' : ''}
          >
            Todos
          </button>
          <button
            onClick={() => handleFilterChange('needReady')}
            className={filter === 'needReady' ? 'active-filter' : ''}
          >
            A executar
          </button>
          <button
            onClick={() => handleFilterChange('readyToCall')}
            className={filter === 'readyToCall' ? 'active-filter' : ''}
          >
            Precisa Chamada
          </button>
          <button
            onClick={() => handleFilterChange('called')}
            className={filter === 'called' ? 'active-filter' : ''}
          >
            Foram chamados
          </button>
          <button
            onClick={() => handleFilterChange('completed')}
            className={filter === 'completed' ? 'active-filter' : ''}
          >
            Trabalhos Colocados
          </button>
        </div>
      </div>
      
      <div className="search-container">
        <input
          type="text"
          placeholder="Procurar Paciente"
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        <button onClick={handleClearSearch}>Apagar</button>

        <select 
          value={selectedDoctor} 
          onChange={(e) => setSelectedDoctor(e.target.value)}
          className="doctor-filter"
        >
          <option value="Todos">--</option>
          <option value="JP">JP</option>
          <option value="Ricardo">Ricardo</option>
          <option value="João Rua">João Rua</option>
          <option value="Bernardo">Bernardo</option>
          <option value="Sónia">Sónia</option>
          <option value="Maria do Carmo">Maria do Carmo</option>
          <option value="João Pinto">João Pinto</option>
          <option value="Marta Vale">Marta Vale</option>
        </select>
      </div>

      {filter === 'completed' && (
        <div className="date-filter" style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '10px' }}>
            <label>De:</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="dd/mm/yyyy"
              className="date-input"
            />
          </div>
          <div style={{ marginLeft: '10px', marginRight: '10px' }}>
            <label>Ate:</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="dd/mm/yyyy"
              className="date-input"
            />
          </div>
          <button onClick={handleClearDates} className="clear-button">
            Voltar
          </button>
        </div>
      )}

      <div className="scrollable-container">
        <PatientList
          filter={filter}
          fetchPatients={fetchPatients}
          openModal={handlePatientClick}
          patients={filteredPatients}
        />
      </div>
      <div className="add-patient-container">
        <button className="add-patient-button" onClick={() => openModal()}>+</button>
      </div>
      <PatientForm
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        fetchPatients={fetchPatients}
        initialData={currentPatient}
      />
    </div>
  );
};

export default App;

