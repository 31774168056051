import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';

const determineStatus = ({ name, assistantName, doctorName, typeOfWork, dueDate, initialStatus }) => {
  if (initialStatus === 'notYetFilledOut' && name && assistantName && doctorName && typeOfWork && dueDate) {
    return 'needReady';
  }
  return initialStatus || 'notYetFilledOut';
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const normalizeDate = (dateString) => {
  if (!dateString) return null;
  const date = new Date(dateString);
  date.setUTCHours(12, 0, 0, 0); // Set time to midday to avoid timezone issues
  return date.toISOString();
};

const PatientForm = ({ isOpen, onRequestClose, initialData, fetchPatients }) => {
  const [name, setName] = useState('');
  const [assistantName, setAssistantName] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [typeOfWork, setTypeOfWork] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [notes, setNotes] = useState('');
  const [status, setStatus] = useState('notYetFilledOut');
  const [cor, setCor] = useState(''); // New state for "cor"

  useEffect(() => {
    if (initialData) {
      setName(initialData.name || '');
      setAssistantName(initialData.assistantName || '');
      setDoctorName(initialData.doctorName || '');
      setTypeOfWork(initialData.typeOfWork || '');
      setDueDate(initialData.dueDate ? initialData.dueDate.split('T')[0] : ''); // Retain the original date format
      setNotes(initialData.notes || '');
      setStatus(initialData.status || 'notYetFilledOut');
      setCor(initialData.cor || ''); // Initialize "cor" if editing an existing patient
    } else {
      setName('');
      setAssistantName('');
      setDoctorName('');
      setTypeOfWork('');
      setDueDate('');
      setNotes('');
      setStatus('notYetFilledOut');
      setCor(''); // Reset "cor"
    }
  }, [initialData, isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Ensure all fields are filled correctly
    if (!name || !assistantName || !doctorName || !typeOfWork || !dueDate || (typeOfWork === 'coroa' && !cor)) {
      alert("Necessita todos os campos preenchidos.");
      return;
    }
  
    const patientData = {
      name,
      assistantName,
      doctorName,
      typeOfWork,
      dueDate,
      notes,
      cor: typeOfWork === 'coroa' ? cor : '', // Only include "cor" if the type of work is "coroa"
    };
  
    try {
      if (initialData && initialData._id) {
        await axios.put(`https://backendlab.vercel.app/patients/${initialData._id}`, patientData);
      } else {
        const response = await axios.post('https://backendlab.vercel.app/patients', {
          ...patientData,
          entryTime: new Date(),
        });
        console.log('Patient added with response:', response.data);
      }
      onRequestClose();
      fetchPatients();
    } catch (error) {
      console.error('Error saving patient:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nome do Paciente"
          required
        />
        <select value={assistantName} onChange={(e) => setAssistantName(e.target.value)}>
          <option value="">Assistente:</option>
          <option value="Filipa">Filipa</option>
          <option value="Bruna">Bruna</option>
          <option value="Sandra">Sandra</option>
          <option value="Patrícia">Patrícia</option>
        </select>
        <select value={doctorName} onChange={(e) => setDoctorName(e.target.value)}>
          <option value="">Doutor(a)</option>
          <option value="JP">JP</option>
          <option value="Ricardo">Ricardo</option>
          <option value="João Rua">João Rua</option>
          <option value="Bernardo">Bernardo</option>
          <option value="Sónia">Sónia</option>
          <option value="Maria do Carmo">Maria do Carmo</option>
          <option value="João Pinto">João Pinto</option>
          <option value="Marta Vale">Marta Vale</option>
        </select>
        <select value={typeOfWork} onChange={(e) => setTypeOfWork(e.target.value)}>
          <option value="">Tipo de trabalho</option>
          <option value="prótese removível">Prótese removível</option>
          <option value="prótese fixa">Prótese fixa</option>
          <option value="goteiras">Goteiras</option>
          <option value="coroa">Coroa(s)</option>
          <option value="urgência">Urgência</option>
        </select>
        
        
        {typeOfWork === 'coroa' && (
          <input
            type="text"
            value={cor}
            onChange={(e) => setCor(e.target.value)}
            placeholder="Cor:"
          />
        )}

        <label htmlFor="dueDate">Prazo de Entrega:</label>
        <input
          type="date"
          id="dueDate"
          value={dueDate}
          onChange={(e) => setDueDate(e.target.value)}
        />
        <textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Notas (Opcional)"
        />
        <button type="submit">Guardar</button>
      </form>
    </Modal>
  );
};

export default PatientForm;
















